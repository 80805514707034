import actions from './actions'
import mutations from './mutations'

const state = {
  pageReady: false,
  ports: [],
  port: 0,
  baseUrl: 'https://i.mctech.vip',
  domain: '',
  tenantName: '',
  forceMobileLogin: false,
  customBackground: false,
  clients: [],
  qrCodeUrl: '',
  qrCodeImage: '',
  qrCodeStatus: null,
  qrCodeSuccess: false,
  qrCodeBindingToken: '', // 扫码成功后的绑定Token
  coolDown: 0, // 再次获取手机验证的冷却时间
  destUrl: '', // 登录成功后跳转的地址
  showCaptcha: false, // 是否显示校验码输入框和图片
  errorMessage: '', // 错误信息
  isLoginId: true, // 不是使用扫码方式进行登录的
  isShowBindPrompt: false, // 登录后显示微信绑定提示
  bindedUrl: '', // 绑定后跳转的地址
  slides: [ // 轮播图
    {
      title: '工程量贯通管理',
      image: '/assets/images/portal/product-4.png'
    },
    {
      title: '生产进度管理',
      image: '/assets/images/portal/product-1.png'
    },
    {
      title: '建设方投资控制管理',
      image: '/assets/images/portal/product-2.png'
    },
    {
      title: '数字化成本量价双控',
      image: '/assets/images/portal/product-3.png'
    },
    {
      title: '物资数字化管理',
      isBelongToYL: true,
      image: '/assets/images/portal/product-5.png'
    }
  ],
  qrCodeTransReady: false, // 第一次微信登录扫描二维码动画
  preSync: false, // 预发布环境是否还在恢复数据库中
  maintenance: {}, // 维护公告
  maintenanceType: 'portal'
}

const store = new Vuex.Store({
  state,
  actions,
  mutations
})
store.$http = axios

export default store
