import http from '../../../utils/http'

let qrCodeScanHandler = null
let coolDownHandler = null

function loginSuccess (store, destUrl, isPasswordType) {
  clearInterval(qrCodeScanHandler)
  clearInterval(coolDownHandler)
  if (destUrl.indexOf('http') === 0) {
    store.commit('setDestUrl', destUrl)
    return
  }
  if (destUrl === '/' && store.state?.qrCodeBindingToken) {
    http
      .post('/cas/wx/bind', { bindingToken: store.state.qrCodeBindingToken })
      .then(result => {
        if (result && result.status === 'ok') store.commit('setDestUrl', '/')
        else store.commit('setError', result && result.error ? result.error.desc ? result.error.desc : '未知错误' : '未知错误')
      })
      .catch(error => {
        store.commit('setError', error)
      })
  }
  if (destUrl.indexOf('validate?ticket') > -1) {
    http.get(destUrl).then(success => {
      if (store.state.qrCodeBindingToken) {
        http
          .post('/cas/wx/bind', { bindingToken: store.state.qrCodeBindingToken })
          .then(result => {
            if (result && result.status === 'ok') store.commit('setDestUrl', '/')
            else store.commit('setError', result && result.error ? result.error.desc ? result.error.desc : '未知错误' : '未知错误')
          })
          .catch(error => {
            store.commit('setError', error)
          })
      } else if (store.state.qrCodeUrl && store.state.isLoginId) {
        // 不是扫码登录，需要显示绑定用的二维码
        const ignoreQrCodeBind = window.localStorage.getItem('ignoreQrCodeBind')
        if (ignoreQrCodeBind !== 'ignore') {
          // 获取登录用户信息，先生成上下文
          http.get('/services/login-user').then(result => {
            if (!result.weChatUnionId) {
              // 用户没绑定，显示绑定二维码
              store.commit('setBindedUrl', destUrl)
              store.dispatch('getQRCode')
            } else {
              store.commit('setDestUrl', '/')
            }
          })
        } else {
          store.commit('setDestUrl', '/')
        }
      } else {
        store.commit('setDestUrl', '/')
      }
    }).catch(error => {
      store.commit('setError', error)
    })
  } else {
    store.commit('setDestUrl', '/')
  }
}

const actions = {
  getLoginConfig: function (store) {
    const errorMessage = window.localStorage?.getItem('loginError')
    if (errorMessage) {
      window.localStorage?.removeItem('loginError')
      store.commit('setError', errorMessage)
    }

    return http
      .get('/services/public/login-config', { href: document.location.href })
      .then(result => {
        if (result.redirect) {
          document.location.replace(result.redirect)
        } else {
          store.commit('setLoginConfig', result)
          // todo: 从配置中获取
          for (let i = 8736; i < 8746; i++) store.state.ports.push(i)
          for (let i = 51736; i < 51746; i++) store.state.ports.push(i)
          // if (result.qrCodeUrl) {
          //   store.dispatch('getQRCode')
          // }
        }
      })
      .catch(error => {
        store.commit('setError', error)
      })
  },
  getQRCode: function (store) {
    http
      .get(store.state.qrCodeUrl)
      .then(result => {
        if (result.qrcode) {
          store.commit('setQRCode', result.qrcode)
          store.commit('setQRCodeStatus', null)
          qrCodeScanHandler = setInterval(function () {
            store.dispatch(store.state.bindedUrl ? 'getQRCodeBindStatus' : 'getQRCodeScanStatus', result.sceneId)
          }, 1000)
        }
      })
  },
  getQRCodeScanStatus: function (store, sceneId) {
    http
      .get(store.state.qrCodeUrl.replace('/qrcode', '/qrcode-status'), { scene_id: sceneId, domain: store.state.domain, headers: { 'x-serial-sign': window.localStorage?.getItem('MCTECH_CLOUD_CLIENT_SID') } })
      .then(response => {
        const result = response.headers ? response.data : response
        if (result.code !== 'waiting') clearInterval(qrCodeScanHandler)
        if (result.code === 'expired') {
          store.commit('setQRCodeStatus', `${result.desc}, 点击这里刷新`)
        } else if (result.code === 'success') {
          if (result.login && result.login.status === 'not_found') {
            store.commit('setError', '用户不属于当前域名对应对应的租户')
            return
          }
          store.state.isLoginId = false
          if (result.login.status === 'ok') {
            if (response.headers && response.headers['x-serial-sign']) window.localStorage.setItem('MCTECH_CLOUD_CLIENT_SID', response.headers['x-serial-sign'])
            store.commit('setQRCodeStatus', `${result.desc}, 自动登录中......`)
            loginSuccess(store, result.login.service)
          } else {
            store.commit('setQRCodeBindingToken', result.login.bindingToken)
            store.commit('setQRCodeStatus', `${result.desc}, 需要登录绑定`)
          }
        }
      })
  },
  getQRCodeBindStatus: function (store, sceneId) {
    http
      .get(store.state.qrCodeUrl.replace('/qrcode', '/qrcode-status' + (store.state.domain ? `?domain=${store.state.domain}` : '')), { scene_id: sceneId })
      .then(response => {
        const result = response.headers ? response.data : response
        if (result.code !== 'waiting') clearInterval(qrCodeScanHandler)
        if (result.code === 'expired') {
          store.commit('setQRCodeStatus', `${result.desc}, 点击这里刷新`)
        } else if (result.code === 'success') {
          // 记住，不再显示绑定二维码
          store.commit('setQRCodeStatus', `${result.desc}, 自动登录中......`)
          store.commit('setQRCodeBindingToken', result.login.bindingToken)
          window.localStorage.setItem('ignoreQrCodeBind', 'ignore')
          loginSuccess(store, '/')
        }
      })
  },
  getSMSCode: function (store, data) {
    const phoneNumber = data.loginId
    http
      .post('/cas/sms/send-sms-once.do', { phone: phoneNumber, domain: store.state.domain || undefined })
      .then(result => {
        store.commit('setCoolDown', 60)
        coolDownHandler = setInterval(function () {
          store.commit('decCoolDown')
          if (store.state.coolDown <= 0) {
            clearInterval(coolDownHandler)
            store.commit('setCoolDown', 0)
          }
        }, 1000)
      })
      .catch(error => {
        store.commit('setError', error)
      })
  },
  loginPassword: function (store, data) {
    const loginId = data.loginId

    if (data.otpCode) {
      http
        .post('/cas/otp/login.do', {
          username: loginId,
          password: data.password,
          otpcode: data.otpCode
        })
        .then(result => {
          loginSuccess(store, result.service)
        })
        .catch(error => {
          store.commit('setError', error)
        })
      return
    }
    const login = function () {
      const lengthValidator = data.password.length >= 8 && data.password.length <= 20
      const validators = [
        /[a-z]/.test(data.password),
        /[A-Z]/.test(data.password),
        /[0-9]/.test(data.password),
        new RegExp(/[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/).test(data.password)
      ]
      const maxCount = validators.filter(v => v).length
      const passwordValid = lengthValidator && maxCount >= 3
      if (store.state.login.forcePasswordStrategy && !passwordValid) {
        store.commit('setError', '密码强度低, 点击忘记密码, 修改密码后登录。')
        return
      }
      http
        .post('/cas/login.do', {
          username: loginId,
          password: data.password,
          captcha: data.captcha,
          headers: { 'x-serial-sign': window.localStorage?.getItem('MCTECH_CLOUD_CLIENT_SID') }
        })
        .then(response => {
          const result = response.headers ? response.data : response
          if (response.headers && response.headers['x-serial-sign']) window.localStorage.setItem('MCTECH_CLOUD_CLIENT_SID', response.headers['x-serial-sign'])
          loginSuccess(store, result.service, true)
        })
        .catch(error => {
          store.commit('setError', error)
        })
    }
    if (store.state.login.integrationChecker) {
      // 检测用户是否集成了，如果是，跳转到根组织
      http.get('/services/public/is-integration', { loginId }).then(result => {
        if (result.isIntegration) {
          document.location.replace(document.location.href.replace('login.html', ''))
        } else {
          login()
        }
      }).catch(error => {
        store.commit('setError', error)
      })
    } else {
      login()
    }
  },
  loginSMS: function (store, data) {
    const phoneNumber = data.loginId
    const login = function () {
      http
        .post('/cas/sms/login.do', {
          phone: data.loginId,
          domain: store.state.domain || undefined,
          code: data.smsCode,
          captcha: data.captcha,
          headers: { 'x-serial-sign': window.localStorage?.getItem('MCTECH_CLOUD_CLIENT_SID') }
        })
        .then(response => {
          const result = response.headers ? response.data : response
          if (response.headers && response.headers['x-serial-sign']) window.localStorage.setItem('MCTECH_CLOUD_CLIENT_SID', response.headers['x-serial-sign'])
          loginSuccess(store, result.service)
        })
        .catch(error => {
          store.commit('setError', error)
        })
    }
    if (store.state.login.integrationChecker) {
      // 检测用户是否集成了，如果是，跳转到根组织
      http.get('/services/public/is-integration', { loginId: phoneNumber }).then(result => {
        if (result.isIntegration) {
          document.location.replace(document.location.href.replace('login.html', ''))
        } else {
          login()
        }
      }).catch(error => {
        store.commit('setError', error)
      })
    } else {
      login()
    }
  },
  checkCaptcha: function (store, params) {
    http
      .get('/cas/check-captcha.do', params)
      .then(result => {
        store.commit('setShowCaptcha', result ? new Date().getTime() : '')
      })
      .catch(error => {
        store.commit('setError', error)
      })
  },
  login: function (store, ticket) {
    loginSuccess(store, `/validate?ticket=${ticket}`)
  },
  stopQrCodeScan: function () {
    clearInterval(qrCodeScanHandler)
  }
}

export default actions
