//
//
//
//
//
//
//
//
//
//
//
//
//

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: {
    swiper,
    swiperSlide
  },
  props: ['slides', 'swiperKey'],
  data () {
    return {
      swiperOption: {
        loop: true, // 开启循环轮播
        autoplay: {
          delay: 3000, // 自动播放间隔时间
          disableOnInteraction: false // 用户操作后是否停止自动播放
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true
        // },
        speed: 1000
      }
    }
  },
  mounted () {
  }
}
