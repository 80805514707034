import { registerIcons } from 'mussel'

import bell from './bell.svg'
import bellRingingFilled from './bell-ringing-filled.svg'
import bug from './bug.svg'
import chevronLeft from './chevron-left.svg'
import chevronRight from './chevron-right.svg'
import deviceMobile from './device-mobile.svg'
import eye from './eye.svg'
import eyeOff from './eye-off.svg'

registerIcons({
  bell,
  bellRingingFilled,
  bug,
  chevronLeft,
  chevronRight,
  deviceMobile,
  eye,
  eyeOff
})
