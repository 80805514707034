<template>
  <div class="app-container">
    <div class="swiper">
      <swiper ref="mySwiper" :key="swiperKey" :options="swiperOption">
        <swiper-slide v-for="(item, index) in slides" :key="index">
          <div class="swiper-image-title" v-html="item.title" />
          <img :src="item.image" alt="轮播图">
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination swiper-pagination-bullets" />
    </div>
  </div>
</template>
<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'

  export default {
    components: {
      swiper,
      swiperSlide
    },
    props: ['slides', 'swiperKey'],
    data () {
      return {
        swiperOption: {
          loop: true, // 开启循环轮播
          autoplay: {
            delay: 3000, // 自动播放间隔时间
            disableOnInteraction: false // 用户操作后是否停止自动播放
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          // effect: 'fade',
          // fadeEffect: {
          //   crossFade: true
          // },
          speed: 1000
        }
      }
    },
    mounted () {
    }
  }
</script>
<style scoped>
.app-container {
  width: 100%;
  .swiper-image-title {
    letter-spacing: 0.1em;
    height: 40px;
    margin-bottom: 16px;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    strong {
      color: #008CD6;
    }
  }
  .swiper {
    width: 100%;
    > .swiper-container {
      width: 100%;
    }
  }

  .swiper-slide {
    width: 100% !important;
    > img {
      width: 100%;
      object-fit: contain;
    }
  }
  .swiper-pagination {
    position: relative;
    padding-top: 16px;

    .swiper-pagination-bullet {
      width: 28px;
      height: 4px;
      margin: 4px 8px;
      border-radius: 0px;
      background-color: #CFD5E2;
      opacity: 1;
    }

    & .swiper-pagination-bullet-active {
        background-color: #008CD6;
    }
  }
}
</style>
