//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'throttle-debounce'

import Carousel from './carousel.vue'
import QrCodeDesktop from './qr-code-desktop.vue'
import LoginContent from './login-content.vue'
import AppQrCode from './app-qr-code.vue'
import BindPrompt from './bind-prompt.vue'
import MaintenanceDialog from './dialog-maintenance.vue'

export default {
  components: {
    Carousel,
    QrCodeDesktop,
    LoginContent,
    AppQrCode,
    BindPrompt,
    MaintenanceDialog
  },
  data () {
    return {
      logo: `/assets/images/portal/logo.png?ts=${+new Date()}`,
      callback: null,
      carouselSizeClass: 'carousel-size-small',
      InternetNo: '京公网安备 11010802037335号',
      InternetNo2: '京ICP备18012358号-1',
      swiperKey: `init${Math.random().toString(36).substr(2)}`,
      isQrPage: true
    }
  },
  computed: {
    tenantName () {
      return this.$store.state.tenantName
    },
    slides () {
      return this.$store.state.slides.map(item => {
        return {
          ...item,
          title: item.isBelongToYL
            ? `<p>易龙<strong>${item.title}</strong>解决方案</p>`
            : `<p>梦诚<strong>${item.title}</strong>解决方案</p>`
        }
      })
    },
    isShowQRCode () {
      return !!this.$store.state.qrCodeUrl
    },
    isShowBindPrompt () {
      return this.$store.state.isShowBindPrompt
    },
    cornerImage () {
      return !this.$store.state.qrCodeUrl || this.$store.state.qrCodeTransReady
        ? null
        : this.isQrPage
          ? 'url(/assets/images/portal/pc-login-corner.png)'
          : 'url(/assets/images/portal/qr-login-corner.png)'
    },
    qrCodeTransReady () {
      return this.$store.state.qrCodeTransReady
    },
    preSync () {
      return this.$store.state.preSync
    },
    isShowMaintenanceBell () {
      return this.$store.state.maintenance?.startTime
    }
  },
  watch: {
    isShowQRCode (newValue) {
      this.isQrPage = newValue
      if (!newValue) {
        this.$store.dispatch('stopQrCodeScan')
      } else {
        this.$store.dispatch('getQRCode')
      }
    }
  },
  mounted () {
    this.$nextTick(async () => {
      const me = this
      this.isQrPage = this.isShowQRCode

      window.jsonpAdapter = function (config) {
        return new Promise(() => {
          const script = document.createElement('script')
          script.src = config.url
          script.onload = function () {
            me.$store.state.ports = []
            document.body.removeChild(script)
          }
          script.onerror = function () {
            document.body.removeChild(script)
            window.jsonpErrorCount++
            if (window.jsonpErrorCount === 3) {
              window.queryUsers(me.$store.state.ports)
            }
          }
          document.body.appendChild(script)
        })
      }

      window.fnQueryClients = function (datas, port) {
        me.$store.commit('setPort', port)
        window.localStorage?.setItem('port', port)

        if (datas.serialSign) window.localStorage?.setItem('MCTECH_CLOUD_CLIENT_SID', datas.serialSign)
        me.$store.commit('setClients', datas.clients)
      }

      window.queryUsers = function (ports) {
        // 是否记录了有效的服务端口，如果记录了，放到第一个
        const port = window.localStorage?.getItem('port')
        if (port) {
          const index = ports.findIndex(item => item === Number(port))
          ports.unshift(ports.splice(index, 1)[0])
        }

        window.jsonpErrorCount = 0
        const currentPorts = ports.splice(0, 3)
        for (let i = 0; i < currentPorts.length; i++) {
          const url = `http://127.0.0.1:${currentPorts[i]}/clients?callback=fnQueryClients&baseUrl=${encodeURIComponent(me.$store.state.baseUrl)}`
          axios({ url, adapter: window.jsonpAdapter })
        }
      }

      await this.$store.dispatch('getLoginConfig')

      if (this.$store.state.qrCodeUrl) window.queryUsers(this.$store.state.ports) // 二维码获取成功，才需要从本机服务中获取登录过的用户列表

      const className = this.getCarouselSizeClass()
      if (this.carouselSizeClass !== className) {
        this.carouselSizeClass = className
      }
    })
  },
  methods: {
    resize () {
      debounce(200, () => {
        const className = this.getCarouselSizeClass()
        if (this.carouselSizeClass !== className) {
          this.carouselSizeClass = className
          this.carousel?.resize?.()
        }
        this.swiperKey = Math.random().toString(36).substr(2)
      })()
    },
    getCarouselSizeClass () {
      if (this.$store.state.customBackground) return 'carousel-size-none'
      const { width, height } = this.$el.getBoundingClientRect()
      return width > 1440 && height > 900
        ? 'carousel-size-big'
        : width < 1281 || height < 721
          ? 'carousel-size-none'
          : 'carousel-size-small'
    },
    changLoginType () {
      if (!this.isShowQRCode || this.qrCodeTransReady) return
      this.isQrPage = !this.isQrPage
      if (!this.isQrPage) {
        this.$store.dispatch('stopQrCodeScan')
      } else {
        this.$store.dispatch('getQRCode')
      }
    },
    showMaintenance () {
      this.$refs.dlgMaintenance.show()
    }
  }
}

