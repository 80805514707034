//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'

export default {
  data () {
    return {
      noPrompt: false,
      showDialog: false,
      isHideMaintenance: null
    }
  },
  computed: {
    maintenanceType () {
      return this.$store.state.maintenanceType
    },
    maintenance () {
      return this.$store.state.maintenance
    },
    startTime () {
      const maintenance = this.$store.state.maintenance
      if (maintenance.startTime) {
        return dayjs(new Date(maintenance.startTime.replace(/-/g, '/')).getTime()).format('YYYY-MM-DD HH:mm')
      }
      return ''
    },
    endTime () {
      const maintenance = this.$store.state.maintenance
      if (maintenance.startTime) {
        return dayjs(new Date(maintenance.startTime.replace(/-/g, '/')).getTime() + maintenance.splash * 60 * 1000).format('YYYY-MM-DD HH:mm')
      }
      return ''
    },
    summary () {
      return `为了给您提供更优质的服务，我公司计划于 ${this.startTime} 起进行系统维护，预计持续时间${this.maintenance.splash}分钟。由此给您带来的不便敬请谅解，感谢您的理解与支持。`
    },
    content () {
      let contents = []
      const maintenance = this.$store.state.maintenance
      if (maintenance.contents) {
        const products = []
        for (let i = 0; i < maintenance.products.length; i++) {
          const code = maintenance.products[i]
          const name = maintenance.productNames[i]
          if ((this.$store.state.maintenanceType === 'portal' && (code === 'iwop-portal' || code === 'other')) ||
            (this.$store.state.maintenanceType === 'product' && (code !== 'iwop-portal' && code !== 'other'))) { products.push({ code, name }) }
        }

        products.forEach(item => {
          const productContents = maintenance.contents[item.code].split('\n')
          if (item.code !== 'other') {
            contents.push(`${item.name}：`)
            for (let i = 0; i < productContents.length; i++) {
              productContents[i] = `    ${productContents[i]}`
            }
          }
          contents = contents.concat(productContents)
          contents.push('')
        })
        return contents.join('\n')
      }
      return ''
    },
    isShowMaintenance () {
      let ignoreMaintenance = false
      if (this.maintenance.startTime) {
        const cookieName = `maintenance-${this.maintenanceType}-${this.maintenance.startTime.replace(' ', '-').replace(/:/g, '-')}`
        ignoreMaintenance = document.cookie.indexOf(`${cookieName}=true`) !== -1
      }
      return this.maintenance?.startTime && (this.showDialog || !ignoreMaintenance) && !this.isHideMaintenance
    }
  },
  watch: {
    noPrompt (newValue) {
      if (newValue) {
        const cookieName = `maintenance-${this.maintenanceType}-${this.maintenance.startTime.replace(' ', '-').replace(/:/g, '-')}`
        const result = document.cookie.indexOf(`${cookieName}=true`) === -1
        if (result) {
          const date = new Date()
          date.setTime(date.getTime() + 3 * 24 * 3600 * 1000)
          document.cookie = `${cookieName}=true;expires=${date.toGMTString()}`
        }
      }
    }
  },
  methods: {
    show () {
      this.showDialog = true
      this.isHideMaintenance = false
    },
    hide () {
      this.showDialog = false
      this.isHideMaintenance = true
    }
  }
}
