function getLocalState (key) {
  return window.localStorage?.getItem(key)
}

function setLocalState (key, value) {
  window.localStorage?.setItem(key, value)
}

function removeLocalState (key) {
  window.localStorage?.removeItem(key)
}

const mutations = {
  setError: (state, error) => {
    state.errorMessage = error.data?.desc || error.data || error.message || error.toString()
  },
  setClients: (state, clients) => {
    state.clients = clients
  },
  setPort: (state, port) => {
    console.log(`=============== clients: ${port} =============`)
    console.log(state.clients)
    state.port = port
  },
  setLoginConfig: (state, config) => {
    state.customBackground = config.background || false
    state.tenantName = config.name || '梦诚云'
    if (config && config.valid && config.domain) {
      state.domain = config.domain
    }
    if (config.maintenance && (config.maintenance.deploys.includes('portal') || config.maintenance.deploys.includes('other'))) {
      state.maintenance = config.maintenance
    } else {
      state.maintenance = {} // 手工触发 watch，防止覆盖登录输入框
    }
    state.baseUrl = config.baseUrl
    state.forceMobileLogin = config.login && config.login.forceMobileLogin
    state.qrCodeUrl = config.qrCodeUrl
    state.login = config.login
    state.preSync = config.preSync
  },
  setQRCode: (state, qrCodeImage) => {
    state.qrCodeImage = qrCodeImage
  },
  setQRCodeStatus: (state, status) => {
    if (status && status.indexOf('自动登录中') > -1) state.qrCodeSuccess = true
    state.qrCodeStatus = status
  },
  setQRCodeBindingToken: (state, token) => {
    state.qrCodeBindingToken = token
  },
  setCoolDown: (state, coolDown) => {
    state.coolDown = coolDown
  },
  decCoolDown: (state, coolDown) => {
    state.coolDown--
  },
  setShowCaptcha: (state, showCaptcha) => {
    state.showCaptcha = showCaptcha
  },
  setBindedUrl: (state, destUrl) => {
    state.bindedUrl = destUrl
    state.isShowBindPrompt = true
  },
  setDestUrl: (state, destUrl) => {
    const notified = getLocalState('MCTECH_MXZ_LOGIN_NOTIFIED')
    if (notified == null) {
      const smsNotified = getLocalState('MCTECH_MXZ_SMS_LOGIN_NOTIFIED')
      setLocalState('MCTECH_MXZ_LOGIN_NOTIFIED', smsNotified ?? 0)
      if (smsNotified != null) removeLocalState('MCTECH_MXZ_SMS_LOGIN_NOTIFIED')
    }
    state.destUrl = destUrl
  }
}

export default mutations
