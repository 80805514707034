import './login.html'
import './css/index'
import '../../static-contents/assets/icons/index'

import store from './store/index'
import layout from './components/layout.vue'

(function () {
  return new Vue({
    el: '#layout',
    components: {
      'cmp-layout': layout
    },
    template: '<cmp-layout></cmp-layout>',
    store
  })
})()
