//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['title', 'showLogo', 'qrCodeSize'],
  emits: ['doLogin'],
  data () {
    return {
      prompt: '<p>扫码关注完成账号绑定<br/>下次可扫码直接登录</p>',
      transConfig: {}
    }
  },
  computed: {
    qrCodeImage () {
      return `url(${this.$store.state.qrCodeImage})`
    },
    mcLogoImage () {
      return this.showLogo ? 'url(/assets/images/portal/qr-code-border.png)' : ''
    },
    qrCodeStatus () {
      return this.$store.state.qrCodeStatus && this.$store.state.qrCodeStatus.indexOf('刷新') > -1 ? '' : this.$store.state.qrCodeStatus
    },
    qrCodeInvalid () {
      return this.$store.state.qrCodeStatus && this.$store.state.qrCodeStatus.indexOf('刷新') > -1
    },
    qrCodeSuccess () {
      return this.$store.state.qrCodeSuccess
    },
    qrCodeSuccessInfo () {
      return this.$store.state.qrCodeSuccess ? '<p>自动登录中，请稍候......</p>' : '<p>首次扫码<br>请完成登录绑定</p>'
    },
    qrCodeBindingToken () {
      return this.$store.state.qrCodeBindingToken
    },
    isShowBindPrompt () {
      return this.$store.state.isShowBindPrompt
    },
    qrCodeSizeClass () {
      switch (this.qrCodeSize) {
        case 'middle':
          return 'qr-code-size-middle'
        case 'half':
          return 'qr-code-size-half'
        case 'quarter':
          return 'qr-code-size-quarter'
        default:
          return 'qr-code-size-big'
      }
    },
    expireMaskSizeClass () {
      switch (this.qrCodeSize) {
        case 'middle':
          return 'expire-mask-size-middle'
        case 'half':
          return 'expire-mask-size-half'
        case 'quarter':
          return 'expire-mask-size-quarter'
        default:
          return 'expire-mask-size-big'
      }
    },
    successMaskSizeClass () {
      switch (this.qrCodeSize) {
        case 'middle':
          return 'success-mask-size-middle'
        case 'half':
          return 'success-mask-size-half'
        case 'quarter':
          return 'success-mask-size-quarter'
        default:
          return 'success-mask-size-big'
      }
    }
  },
  methods: {
    reload () {
      if (this.$store.state.qrCodeStatus && !this.$store.state.qrCodeBindingToken) this.$store.dispatch('getQRCode')
    }
  }
}
