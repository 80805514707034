import animation from './loading-animation'
import { showMessage } from 'sparrow-popup'

const methods = ['get', 'post', 'put', 'patch', 'delete']
const headers = {
  'x-client-ajax': 'true'
  // 'x-product-id': ctx.productId,
  // 'x-feature-packs': ctx.featurePacks
}

const http = {}
const request = window.iwop
  ? function ({ url, method, headers, params, data }) {
    for (const p in params) {
      if (params[p] !== null && params[p] !== undefined) {
        url += (url.indexOf('?') > 0 ? '&' : '?') + p + '=' + encodeURIComponent(params[p])
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(
        '/iwop/request',
        {
          url,
          method,
          data,
          header: headers
        },
        {
          timeout: 5000
        })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          const res = Object(error.response)
          const { desc, code, response } = Object(res.data)
          const err = {
            message: desc || error,
            code,
            response: response || res
          }
          reject(err)
        })
    })
  }
  : axios

methods.forEach(method => {
  http[method] = function (url, params, data, hideError = false) {
    if (!data && (method === 'post' || method === 'put' || method === 'patch')) {
      data = params
      params = null
    }
    if (method === 'get') {
      params = Object.assign(params || {}, { __: (+new Date()).toString(36) })
    }
    const orgId = Object(params).orgId || this.orgId
    return new Promise((resolve, reject) => {
      animation.start()
      const batchId = http.batchId
      if (batchId) headers['x-batch-id'] = batchId

      const paramHeaders = data?.headers || params?.headers || {}
      if (!paramHeaders['x-serial-sign']) delete paramHeaders['x-serial-sign']
      delete data?.headers
      delete params?.headers

      request({
        url,
        method,
        headers: Object.assign(orgId ? { 'x-org-id': orgId } : {}, headers, paramHeaders),
        params,
        data
        // timeout: 10000
      }).then(response => {
        animation.end()
        if (response.headers['x-serial-sign']) resolve({ data: response.data, headers: response.headers })
        else resolve(response.data)
      }).catch(error => {
        animation.end()
        let { statusCode, status, data } = Object(error.response)
        const { desc } = Object(data)
        status = status || statusCode
        const message = status
          ? (desc || '请求服务端返回错误')
          : '请求服务端失败'
        if (!status || status >= 400) {
          console.error(error)
          if (status === 401) {
            showMessage('没有访问该数据的权限.', 'warning')
          } else if (status == 403) {
            showMessage(desc, 'warning')
          } else {
            showMessage(status === 500 ? '请求服务端返回错误' : message, 'danger')
          }
        }
        const err = {
          message,
          status,
          data
        }
        reject(err)
      })
    })
  }
})

export default http
