//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { notify } from 'mussel'

export default {
  props: ['isBindPage'],
  emits: ['changeShowPage'],
  data () {
    return {
      downloadCorner: 'url(/assets/images/portal/download-app-corner.png)',
      loginType: 'password',
      loginId: '',
      password: '',
      smsCode: '',
      otpCode: '',
      captcha: '',
      verifyImg: '/cas/captcha.do',
      passwordShow: false,
      exceptionCount: 0
    }
  },
  computed: {
    forceMobileLogin () {
      return this.$store.state.forceMobileLogin
    },
    adminLogin () {
      return this.loginType === 'password' && (this.loginId === 'admin' || this.loginId.indexOf('admin@') === 0)
    },
    resetPasswordText () {
      return this.loginType === 'password' ? '忘记密码' : ''
    },
    errorMessage () {
      if (this.$store.state.errorMessage) {
        notify('error', this.$store.state.errorMessage)
        if (['password', 'sms'].includes(this.loginType)) {
          const domain = this.$store.state.domain
          const incDomain = domain && this.loginId.indexOf(`@${domain}`) > -1
          this.$store.dispatch('checkCaptcha', {
            type: this.loginType === 'sms' ? 'sms-code' : 'password',
            sms: this.loginType === 'sms',
            key: this.loginId + (incDomain || this.loginType === 'sms' ? '' : (domain ? '@' + domain : ''))
          })
        }
      }
      const result = this.$store.state.errorMessage
      this.$store.commit('setError', '')
      return result
    },
    coolDown () {
      return this.$store.state.coolDown
    },
    destUrl () {
      if (this.$store.state.destUrl) {
        window.sessionStorage.clear() // 清除 session 数据
        const redirectParams = this.$store.state.destUrl.indexOf('?') >= 0 ? '&enterProduct=intelli-worksite' : '?enterProduct=intelli-worksite'
        document.location.replace(this.$store.state.destUrl + redirectParams)
      }
      return this.$store.state.destUrl
    },
    showCaptcha () {
      // 每次登录出错，都要重新加载个检验码
      this.changeImg()
      return this.$store.state.showCaptcha
    },
    canGetSMSCode () {
      const baseCondition = this.loginId
      return this.loginType === 'sms' && baseCondition && !(this.coolDown > 0)
    },
    canLogin () {
      const baseCondition = this.loginId && (this.loginType === 'sms' ? this.smsCode : this.password)
      const captchaCondition = (!this.$store.state.showCaptcha || this.captcha)
      return this.loginType === 'sms'
        ? baseCondition && captchaCondition
        : this.adminLogin ? baseCondition && this.otpCode : baseCondition && captchaCondition
    },
    isShowQRCode () {
      return !!this.$store.state.qrCodeUrl
    },
    loginText () {
      return this.$store.state.qrCodeBindingToken ? '登录并绑定用户微信' : '登录'
    }
  },
  watch: {
    forceMobileLogin (newValue) {
      if (newValue) this.loginType = 'sms'
    }
  },
  mounted () {
    this.$nextTick(async () => {
      document.querySelector('#account').focus()
    })
  },
  methods: {
    blur () {
      if (this.loginId) {
        const domain = this.$store.state.domain
        const incDomain = domain && this.loginId.indexOf(`@${domain}`) > -1
        this.$store.dispatch('checkCaptcha', {
          type: this.adminLogin ? 'pwd-otp' : this.loginType === 'sms' ? 'sms-code' : 'password',
          sms: this.adminLogin ? false : this.loginType === 'sms',
          key: this.loginId + (incDomain || this.loginType === 'sms' ? '' : (domain ? '@' + domain : ''))
        })
      }
    },
    changeLoginType (loginType) {
      this.loginType = loginType
      this.blur()
    },
    resetPassword () {
      window.open('/password.html')
    },
    activeInput (el) {
      document.querySelectorAll('.login-input-item').forEach(item => item.setAttribute('active', 'false'))
      el.currentTarget.parentElement.setAttribute('active', 'true')
    },
    getSMSCode () {
      if (this.coolDown === 0) {
        if (!/^1\d{10}$/.test(this.loginId)) {
          notify('error', '手机号无效!')
        } else {
          this.$store.dispatch('getSMSCode', {
            loginId: this.loginId
          }).then(() => {
            document.querySelector('.sms-code').focus()
          })
        }
      }
    },
    gotoDownloadPage () {
      this.$emit('changeShowPage', true)
    },
    login () {
      this.$store.commit('setError', '')
      const me = this
      const incDomain = (me.loginId && me.$store.state.domain && me.loginId.indexOf('@' + me.$store.state.domain) > -1)
      // 登录函数包
      const _login = {
        password: function () {
          const domain = me.$store.state.domain
          const options = {
            loginId: me.loginId + (incDomain ? '' : (domain ? '@' + domain : '')),
            password: me.password,
            captcha: me.captcha
          }
          if (me.adminLogin) options.otpCode = me.otpCode
          me.$store.dispatch('loginPassword', options)
        },
        sms: function () {
          me.$store.dispatch('loginSMS', {
            loginId: me.loginId,
            smsCode: me.smsCode,
            captcha: me.captcha
          })
        }
      }

      // 清除原错误信息，这样出错时因为 errorMessage 有变化，就可以调用 errorMessage()，触发 checkCaptcha
      this.$store.commit('setError', '')
      // 获取登录方式对应的参数验证函数和登录函数
      const loginFn = this.loginType === 'sms' ? _login.sms : _login.password
      if (this.canLogin) loginFn()
    },
    changeImg () {
      this.verifyImg = '/cas/captcha.do?ts=' + new Date().getTime()
    }
  }

}
