//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QrCode from './qr-code.vue'

export default {
  components: {
    QrCode
  },
  data () {
    return {
      noPrompt: false
    }
  },
  watch: {
    noPrompt (newValue) {
      if (newValue) window.localStorage.setItem('ignoreQrCodeBind', 'ignore')
      else window.localStorage.removeItem('ignoreQrCodeBind')
    }
  },
  methods: {
    skip () {
      this.$store.commit('setDestUrl', '/')
    }
  }
}
