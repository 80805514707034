//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QrCode from './qr-code.vue'
export default {
  components: {
    QrCode
  },
  data () {
    return {
      currentPageNo: 0
    }
  },
  computed: {
    title () {
      if (this.users?.length > 0) {
        return '使用微信扫码登录或点击头像授权登录'
      } else {
        return '使用微信扫码登录'
      }
    },
    gridContainerSize () {
      if (this.$store.state.qrCodeTransReady) {
        return 'grid-container-bind'
      } else {
        if (this.users?.length > 1) {
          return 'grid-container-quarter'
        } else if (this.users?.length === 1) {
          return 'grid-container-half'
        } else {
          return 'grid-container-normal'
        }
      }
    },
    qrCodeSize () {
      if (this.users?.length === 1) {
        return 'half'
      } else if (this.users?.length > 1) {
        return 'quarter'
      } else {
        return 'big'
      }
    },
    profilePhotoSizeClass () {
      if (this.users?.length > 1) {
        return 'profile-photo-size-quarter'
      } else {
        return 'profile-photo-size-half'
      }
    },
    userLastNameHeightClass () {
      if (this.users?.length > 1) {
        return 'user-last-name-height-quarter'
      } else {
        return 'user-last-name-height-half'
      }
    },
    userLastNameNoFullNameHeightClass () {
      if (this.users?.length > 1) {
        return 'user-last-name-height-quarter-no-full-name'
      } else {
        return 'user-last-name-height-half-no-full-name'
      }
    },
    users () {
      return this.$store.state.clients
    },
    showUsers () {
      if (this.users.length < 2) {
        return this.users.map(item => {
          return {
            ...item,
            lastName: item.userName.length > 3 ? item.userName.slice(0, 1) : item.userName
          }
        })
      } else {
        const sliceUsers = this.users
          .slice(this.currentPageNo * 3, this.currentPageNo * 3 + 3)
          .map(item => {
            return {
              ...item,
              lastName: item.userName.length > 3 ? item.userName.slice(0, 1) : item.userName
            }
          })
        while (sliceUsers.length < 3) {
          sliceUsers.push({
            userId: new Date().getTime() + parseInt(Math.random() * 100),
            userName: '',
            lastName: ''
          })
        }
        return sliceUsers
      }
    },
    isLastPage () {
      return this.users.length < this.currentPageNo * 3 + 3
    },
    qrCodeBindingToken () {
      return this.$store.state.qrCodeBindingToken
    },
    qrCodeTransReady () {
      return this.$store.state.qrCodeTransReady
    }
  },
  watch: {
    qrCodeBindingToken () {
      this.$store.state.qrCodeTransReady = false
      const el = document.querySelector('.grid-container')
      const transConfig = {
        big: {
          transX: 0,
          transY: -30,
          scale: 180 / 248

        },
        middle: {
          transX: 70,
          transY: 30,
          scale: 1
        },
        half: {
          transX: 0,
          transY: -10,
          scale: 1
        },
        quarter: {
          transX: 0,
          transY: -20,
          scale: 1
        }
      }
      const { transX, transY, scale } = transConfig[this.qrCodeSize]
      el.style['grid-template-columns'] = '180px'
      el.style.height = '180px'
      el.style.transform = `translate(${transX}px, ${transY}px) scale(${scale})`
      this.$store.state.qrCodeTransReady = true
    }
  },
  methods: {
    goPre () {
      this.currentPageNo--
    },
    goNext () {
      this.currentPageNo++
    },
    selectUser (user) {
      if (!user.userName) return
      const me = this

      window.jsonpAdapter = function (config) {
        return new Promise(() => {
          const script = document.createElement('script')
          script.src = config.url
          script.onload = function () { document.body.removeChild(script) }
          script.onerror = function () { document.body.removeChild(script) }
          document.body.appendChild(script)
        })
      }

      window.fnLogin = function (result) {
        if (result.serialSign) window.localStorage.setItem('MCTECH_CLOUD_CLIENT_SID', result.serialSign)
        me.$store.dispatch('login', result.ticket) // 使用 ticket 进行身份认证
      }

      axios({
        url: `http://127.0.0.1:${this.$store.state.port}/login?clientId=${user.clientId}&baseUrl=${encodeURIComponent(me.$store.state.baseUrl)}&callback=fnLogin`,
        adapter: window.jsonpAdapter
      })
    }
  }
}
